import clsx from "clsx";
import React, { ReactNode } from "react";

import { formatClassList, joinStrings } from "~/utils";

import styles from "./styles.module.css";

export const FlexColumn: React.FC<{ small?: boolean }> = ({ small, children }) => (
  <div className={`${small ? styles.FlexColumn : ""} flex flex-col justify-center items-center`}>
    {children}
  </div>
);

type FlexRowProps = {
  children: ReactNode;
  className?: string;
};

const FLEX_ROW: string = `
  justify-around
  flex
  flex-wrap
  my-2
`;

export const FlexRow = ({ children, className = "" }: FlexRowProps) => {
  const formattedFlexRow: string = className
    ? formatClassList(joinStrings(" ", FLEX_ROW, className))
    : formatClassList(FLEX_ROW);

  return <div className={formattedFlexRow}>{children}</div>;
};

// export const FlexRow = styled.div<{ small?: boolean; gap?: number }>`
//   display: flex;
//   align-items: flex-start;
//   gap: ${props => (props.gap ? props.gap + 'px' : '10px')};

//   & > div {
//     padding: 0;
//   }
// `;

// export const StyledTr = styled.tr`
//   text-align: center;
// `;

// export const StyledTd = styled.td`
//   text-align: center;

//   &:first-child {
//     border-left: none;
//     border-right: 1px solid black;
//   }
// `;

export const Indent: React.FC = ({ children }) => <p className="mt-2 mb-2 ml-12">{children}</p>;

export const Highlight: React.FC = ({ children }) => (
  <p className="text-lightError font-bold my-4">{children}</p>
);

export const UlCircle: React.FC<{ inherit: boolean }> = ({ inherit, children }) => (
  <ul className={clsx("ml-15", { [`${styles.UlCircle}`]: inherit })}>{children}</ul>
);

export const StyledLi: React.FC = ({ children }) => <li className="py-2">{children}</li>;
// export const StyledH2 = styled.h2`
//   font-size: ${theme`fontSize.sm`};
//   color: ${theme`colors.hs.main.300`};
// `;

export const StyledLink: React.FC<{ href: string }> = ({ children, href }) => (
  <a
    href={href}
    className={`${styles.StyledLink} grid place-items-center gap-2 transition-colors duration-200 px-6 py-2 text-sm text-lime-800 bg-lime-400 hover:bg-lime-500 hover:outline-none focus:outline-none focus:bg-lime-500 active:bg-lime-500 font-bold leading-6 border-0 rounded-full md:text-lg md:leading-8`}
  >
    {children}
  </a>
);

export const StyledA: React.FC = ({ children }) => <div className="bg-red-500">{children}</div>;

export const ItalicP: React.FC = ({ children }) => <p className="inline-block">{children}</p>;

// @To fix
// export const ItalicP = styled.p`
//   display: ${props => props.inline && 'inline-block'};
//   font-style: italic;
//   font-size: ${theme`fontSize.xs`};
//   font-weight: ${props => props.bold && theme`fontWeight.bold`};
// `;

type FootnoteProps = {
  children: ReactNode;
  className?: string;
};

const FOOTNOTE: string = `
  mt-2
  text-xs
  tracking-widest
`;

export const Footnote = ({ children, className = "" }: FootnoteProps) => {
  const formattedFootnote: string = className
    ? formatClassList(joinStrings(" ", FOOTNOTE, className))
    : formatClassList(FOOTNOTE);

  return <p className={formattedFootnote}>{children}</p>;
};
